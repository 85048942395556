import React, { useState, useEffect, useRef } from 'react';
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Box,
  IconButton,
  Flex,
  VStack,
} from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import {
  IoIosPause,
  IoIosPlay,
  IoIosVolumeHigh,
  IoIosVolumeOff,
} from 'react-icons/io';
import { EncoreColors } from '@Encore-FastStartup-Phase-Out/encore-shared';

import { WebAppColors } from '../../constants/colors';
import { Breakpoints } from '../../constants/sizes';

interface ControlProps {
  onPlayPause: () => void;
  playing: boolean;
  onVolumeChangeHandler: (value: number) => void;
  onVolumeSeekUp: (value: number) => void;
  volume: number;
  mute: boolean;
  controlRef: React.RefObject<HTMLDivElement>;
}

const ControlContainer = styled(Box)`
  border-radius: 12px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.3s ease,
    visibility 0.3s ease;

  @media (max-width: ${Breakpoints.Width650}) {
    border-radius: 0;
  }
`;

const CenterPlayButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

const VideoWrapper = styled(Box)`
  position: relative;
  width: 100%;
  height: 100%;
  &:hover ${ControlContainer} {
    opacity: 1;
    visibility: visible;
  }
`;

const Control: React.FC<ControlProps> = ({
  onPlayPause,
  playing,
  onVolumeChangeHandler,
  onVolumeSeekUp,
  volume,
  mute,
  controlRef,
}) => {
  const [isVolumeSliderVisible, setIsVolumeSliderVisible] = useState(false);
  const volumeControlRef = useRef<HTMLDivElement | null>(null);

  const toggleVolumeSlider = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsVolumeSliderVisible(!isVolumeSliderVisible);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      volumeControlRef.current &&
      !volumeControlRef.current.contains(event.target as Node)
    ) {
      setIsVolumeSliderVisible(false);
    }
  };

  const handleClickOnVideo = () => {
    if (!isVolumeSliderVisible) {
      onPlayPause();
    }
  };

  useEffect(() => {
    if (isVolumeSliderVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVolumeSliderVisible]);

  return (
    <VideoWrapper onClick={handleClickOnVideo}>
      <ControlContainer ref={controlRef}>
        <CenterPlayButton
          aria-label="Play/Pause"
          icon={playing ? <IoIosPause /> : <IoIosPlay />}
          onClick={onPlayPause}
          color={EncoreColors.Primary}
          fontSize="xl"
          variant="ghost"
          mx={4}
          backgroundColor={WebAppColors.Black30}
        />
      </ControlContainer>

      <VStack
        spacing={2}
        align="stretch"
        bottom="2%"
        position="absolute"
        width="fit-content"
        right="2%"
        zIndex="2024"
        backgroundColor={WebAppColors.Black30}
        borderRadius="12px"
        paddingTop={isVolumeSliderVisible ? 4 : 0}
        ref={volumeControlRef}
      >
        <Flex align="center" justify="flex-end" flexDirection="column">
          {isVolumeSliderVisible && (
            <Slider
              aria-label="volume-slider"
              value={volume * 100}
              onChange={onVolumeChangeHandler}
              onChangeEnd={onVolumeSeekUp}
              colorScheme="green"
              orientation="vertical"
              height="100px"
              mb={2}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb boxSize={3} />
            </Slider>
          )}
          <IconButton
            aria-label="Toggle Volume Slider"
            icon={mute ? <IoIosVolumeOff /> : <IoIosVolumeHigh />}
            onClick={toggleVolumeSlider}
            color={EncoreColors.Primary}
            fontSize="xl"
            variant="ghost"
            borderRadius="12px"
          />
        </Flex>
      </VStack>
    </VideoWrapper>
  );
};

export default Control;
