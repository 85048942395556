import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { EncoreColors } from '@Encore-FastStartup-Phase-Out/encore-shared';

import { WebAppColors } from '../../constants/colors';
import { Breakpoints } from '../../constants/sizes';

export const ChatWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 80vh;
  gap: 32px;
  justify-content: flex-end;

  @media screen and (max-width: ${Breakpoints.Width1080}) {
    width: 100%;
    height: 80%;
    gap: 16px;
  }
`;

export const ChatsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 17px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  flex-grow: 1;
  padding-right: 8px;

  & > :first-child {
    margin-top: auto !important;
  }

  @media screen and (max-width: ${Breakpoints.Width1080}) {
    gap: 8px;
  }
`;

export const ChatItemContainer = styled(Box, {
  shouldForwardProp: (propName) => !propName.startsWith('$'),
})<{
  $alignCenter?: boolean;
}>`
  display: flex;
  gap: 8px;
  width: 100%;
  align-items: ${(props) => (props.$alignCenter ? 'center' : 'flex-start')};
`;

export const ChatTextWrapper = styled(Box)`
  display: inline-block;
  word-wrap: break-word;
  max-width: 100%;
`;

export const Username = styled.span`
  font-family: 'Manrope bold', serif;
  font-weight: 600;
  color: ${WebAppColors.Gray70};
  white-space: nowrap;
  font-size: 16px;
  line-height: 24px;

  @media screen and (max-width: ${Breakpoints.Width1080}) {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const MessageText = styled('span', {
  shouldForwardProp: (propName) => !propName.startsWith('$'),
})<{ $isBid?: boolean }>`
  font-family: 'Manrope bold', serif;
  font-weight: 600;
  text-shadow: 0 1px 10px ${WebAppColors.Black80};
  margin-left: 4px;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) =>
    props.$isBid ? EncoreColors.Primary : EncoreColors.White};

  @media screen and (max-width: ${Breakpoints.Width1080}) {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const ChatInputContainer = styled(Box)`
  display: flex;
  gap: 8px;
  width: 100%;
`;
