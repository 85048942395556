import axios from 'axios';


const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 60000,
  headers: {
    Accept: 'application/json',
  },
});

instance.interceptors.request.use(async (config: any) => config, (error: any) => {
  return Promise.reject(error);
});

export default instance;
