import React from 'react';
import {
  Box,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  useMediaQuery,
} from '@chakra-ui/react';
import { ArrowBackIcon, ChevronLeftIcon } from '@chakra-ui/icons';

import {
  AuctionInfoWrapper,
  DescriptionContainer,
  SellerActionsWrapper,
  SellerInfoContainer,
} from '../../../screens/styledComponents';
import { Breakpoints } from '../../../constants/sizes';
import PrimaryIconButton from '../../buttons/PrimaryIconButton';
import { SkeletonColors } from '../../../constants/colors';

const ListingInfoSkeleton = () => {
  const [isWidth650] = useMediaQuery([`(max-width: ${Breakpoints.Width650})`]);

  return (
    <AuctionInfoWrapper>
      {!isWidth650 && (
        <Skeleton width="100%" {...SkeletonColors}>
          <PrimaryIconButton
            icon={<ArrowBackIcon boxSize={6} />}
            aria-label={'go-back-button'}
            fontSize="22px"
            marginRight="-8px"
            marginLeft="-14px"
            transparent
            onClick={() => {}}
          />
        </Skeleton>
      )}

      <SellerActionsWrapper>
        <SellerInfoContainer onClick={() => {}}>
          {isWidth650 && (
            <SkeletonCircle size="40px" {...SkeletonColors}>
              <PrimaryIconButton
                icon={<ChevronLeftIcon />}
                aria-label={'go-back-button'}
                fontSize="22px"
                marginRight="-8px"
                marginLeft="-14px"
                transparent
                onClick={() => {}}
              />
            </SkeletonCircle>
          )}
        </SellerInfoContainer>

        <Skeleton width="100%" {...SkeletonColors}>
          <Box height="40px" width="120px" />
        </Skeleton>
      </SellerActionsWrapper>

      <Skeleton width="100%" {...SkeletonColors}>
        <Box height="30px" width="100%" />
      </Skeleton>

      <DescriptionContainer>
        <SkeletonText mt="4" noOfLines={3} spacing="4" {...SkeletonColors}>
          <Box height="20px" width="100%" />
        </SkeletonText>
      </DescriptionContainer>

      {!isWidth650 && (
        <>
          <Skeleton width="100%" {...SkeletonColors}>
            <Box height="40px" width="200px" />
          </Skeleton>

          <Skeleton width="100%" {...SkeletonColors}>
            <Box height="60px" width="100%" />
          </Skeleton>
        </>
      )}
    </AuctionInfoWrapper>
  );
};

export default ListingInfoSkeleton;
