export type QueryDto = {
  pageNo?: number;
  limit?: number;
  searchString?: string;
};

export enum AuctionPageType {
  popularAuctions = 'popularAuctions',
  endingSoon = 'endingSoon',
  recentBuyItNow = 'recentBuyItNow',
  auctions = 'auctions',
  buyItNow = 'buyItNow',
  giveaways = 'giveaways',
}
