export const images = {
  settings: require('../../assets/png/setting_icon.png'),
  share: require('../../assets/png/share_icon.png'),
  user: require('../../assets/png/user.png'),
  smallStar: require('../../assets/png/small_star.png'),
  back: require('../../assets/png/back.png'),
  imageEdit: require('../../assets/png/imageEdit.png'),
  horizontalLine: require('../../assets/png/horizontalLine.png'),
  videoPlaceholder: require('../../assets/png/video-placeholder.png'),
  defaultPicture: require('../../assets/png/defaultProfile.png'),
  doubleLogo: require('../../assets/png/double-logo.png'),
};
