import React from 'react';
import { Skeleton, SkeletonCircle } from '@chakra-ui/react';

import {
  CardContainer,
  ImageContainer,
  ItemDetailsContainer,
  ItemTitle,
  RatingContainer,
  SpaceBetweenContainer,
  UserImageContainer,
} from '../styledComponents';
import { SkeletonColors } from '../../../constants/colors';

export const ItemCardSkeleton = () => (
  <CardContainer>
    <ImageContainer>
      <Skeleton height="200px" {...SkeletonColors} />
    </ImageContainer>

    <ItemDetailsContainer>
      <ItemTitle>
        <Skeleton height="20px" width="70%" {...SkeletonColors} />
      </ItemTitle>

      <SpaceBetweenContainer>
        <Skeleton height="20px" width="50px" {...SkeletonColors} />
        <Skeleton height="20px" width="50px" {...SkeletonColors} />
      </SpaceBetweenContainer>

      <UserImageContainer>
        <SkeletonCircle size="40px" {...SkeletonColors} />
        <Skeleton height="20px" width="100px" {...SkeletonColors} />
      </UserImageContainer>

      <RatingContainer>
        <Skeleton height="20px" width="100px" {...SkeletonColors} />
      </RatingContainer>
    </ItemDetailsContainer>
  </CardContainer>
);
