import styled from '@emotion/styled';
import { Box, IconButton } from '@chakra-ui/react';

import { WebAppColors } from '../../constants/colors';
import { BodyText3 } from '../typography/BodyTexts';
import { Breakpoints } from '../../constants/sizes';

export const ItemBarContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const ItemBarInfoContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SliderContainer = styled(Box)`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

export const StyledNavigatorIconContainer = styled.div`
  height: 3vw;
  width: 3vw;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  background-color: ${WebAppColors.Black30};
  overflow: hidden;
  top: 36%;
  transform: translateY(-36%);
  z-index: 2;
  align-items: center;
  justify-content: center;
  display: flex;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color 0.3s ease;
    z-index: 1;
  }

  &:hover:before {
    background-color: ${WebAppColors.Black30};
  }

  &:active:before {
    background-color: ${WebAppColors.Black60};
  }

  @media screen and (max-width: ${Breakpoints.Width800}) {
    height: 4vw;
    width: 4vw;
  }
`;

export const SlideBox = styled(Box)`
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
`;

export const SellerItemsListWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 80vh;
  gap: 30px;
`;

export const SellerItemsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  flex-grow: 1;
  padding-right: 8px;
`;

export const MoreText = styled(BodyText3)`
  font-weight: 700;
  padding: 16px 14px;
  width: 100%;
  border-bottom: 1px solid ${WebAppColors.Gray20};
`;
