import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import DownloadAppCard from '../components/cards/DownloadAppCard';
import Footer from '../components/common/Footer';
import Header from '../components/common/Header';
import DownloadAppPopup from '../components/common/DownloadAppPopup';
import { useStore } from '../hooks/useStore';
import MobileBanner from '../components/common/MobileBanner';

import { MainComponent, NavigationContainer } from './styledComponents';

type NavigationWrapperProps = {
  children: React.ReactNode;
};

const NavigationWrapper = ({ children }: NavigationWrapperProps) => {
  const {
    uiStore: { isDownloadPopupOpen },
  } = useStore();

  const [isMobileViewport, setIsMobileViewport] = useState(
    window.innerWidth <= 768,
  );

  const handleResize = () => {
    setIsMobileViewport(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <NavigationContainer>
      <Header />
      <MainComponent>{children}</MainComponent>
      <DownloadAppCard />
      <Footer />
      <DownloadAppPopup />

      {(isMobile || isMobileViewport) && <MobileBanner />}
    </NavigationContainer>
  );
};

export default NavigationWrapper;
