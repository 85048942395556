import styled from '@emotion/styled';
import { Badge, Box } from '@chakra-ui/react';
import { EncoreColors } from '@Encore-FastStartup-Phase-Out/encore-shared';

import { XSmallHeading1 } from '../components/typography/Headlines';
import { WebAppColors } from '../constants/colors';
import { Breakpoints } from '../constants/sizes';

export const HomeScreenWrapper = styled(Box)`
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: space-between;
  padding: 80px 8px 40px 80px;

  @media screen and (max-width: ${Breakpoints.Width800}) {
    padding: 20px 4px 40px 40px;
  }
`;

export const InfoWrapper = styled(Box)`
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: fit-content;
  margin-top: 5vh;

  @media screen and (max-width: ${Breakpoints.Width650}) {
    width: 100%;
  }
`;

export const PrimaryTextWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const AuctionsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 0 40px;
  margin-bottom: 100px;

  @media screen and (max-width: ${Breakpoints.Width800}) {
    padding: 0 30px;
  }
`;

export const AuctionsPageWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 35px;
  padding: 20px 41px;
`;

export const AuctionsPageActionsWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 20px;
`;

export const TitleText = styled(XSmallHeading1)`
  width: 100%;
  text-align: center;
`;

export const AuctionsGridContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;

  @media screen and (max-width: ${Breakpoints.Width650}) {
    grid-template-columns: repeat(2, minmax(120px, 1fr));
  }
`;

export const UserAuctionsGridContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;

  @media (max-width: 650px) {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 16px;
  }
`;

export const AuctionScreenWrapper = styled(Box)`
  display: flex;
  padding: 80px;
  justify-content: space-between;

  @media (max-width: ${Breakpoints.Width1080}) {
    padding: 40px;
  }

  @media (max-width: ${Breakpoints.Width650}) {
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;

    padding: 20px 0 20px 0;
  }
`;

export const AuctionInfoWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: fit-content;
  width: 33%;

  @media (max-width: ${Breakpoints.Width650}) {
    width: 100%;
    padding: 0 30px 0 30px;
  }
`;

export const AuctionVideoWrapper = styled(Box)`
  display: flex;
  width: 35%;
  height: 80vh;

  @media screen and (max-width: ${Breakpoints.Width1080}) {
    width: 65%;
  }

  @media screen and (max-width: ${Breakpoints.Width650}) {
    width: 100%;
    height: 80vh;
  }
`;

export const SellerActionsWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  cursor: pointer;

  @media (max-width: ${Breakpoints.Width800}) {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }

  @media (max-width: ${Breakpoints.Width650}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const SellerInfoWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const SellerInfoContainer = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const RatingContainer = styled(Box)`
  display: flex;
  gap: 8px;
  width: 100%;
  align-items: center;
`;

export const DescriptionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

export const AuctionInfoFooter = styled(Box)`
  display: flex;
  gap: 9px;
`;

export const FooterBadges = styled(Badge, {
  shouldForwardProp: (propName) => !propName.startsWith('$'),
})<{ $time?: boolean }>`
  background: ${(props) =>
    props.$time ? WebAppColors.Red15 : WebAppColors.Gray1};
  color: ${EncoreColors.White};
  border: ${(props) =>
    props.$time
      ? `1px solid ${WebAppColors.Red70}`
      : `1px solid ${WebAppColors.Gray50}`};
  border-radius: 12px;
  padding: 12px 16px;
  justify-content: center;
  display: flex;
  align-items: center;
  max-width: 30%;
  min-width: ${(props) => (props.$time ? '30%' : 'fit-content')};
`;

export const SmallScreenButtonsContainer = styled(Box)`
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: flex-start;
  max-width: 460px;
  align-items: center;
`;

export const SmallScreenAuctionsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 30px 20px 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 85%;
  height: 50%;
  justify-content: flex-end;
`;
