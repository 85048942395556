"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_LISTING_FORM = void 0;
var enums_1 = require("../enums");
exports.DEFAULT_LISTING_FORM = {
    title: '',
    category: {
        id: '',
        title: '',
        subcategories: [],
        isActive: false,
        sort: 0,
        image: '',
        createdAt: '',
    },
    subCategory: {
        id: '',
        title: '',
        isActive: false,
        sort: 0,
        image: '',
    },
    description: '',
    type: enums_1.ListingType.DEFAULT,
    price: 0,
    quantity: 0,
    shippingType: enums_1.ShippingType.SELLER_PAYS,
    shippingCost: 0,
    auctionEndDate: '',
    selectedRecommendedTime: false,
    customTimeSlot: {
        hour: '09',
        minute: '00',
        format: 'AM',
    },
    packaging: {
        weight: 12,
        unit: 'oz',
        length: 0,
        width: 0,
        height: 0,
    },
};
