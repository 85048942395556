import React from 'react';
import { Box, Flex, Skeleton, SkeletonCircle } from '@chakra-ui/react';

import { SkeletonColors } from '../../../constants/colors';
import {
  BadgesContainer,
  ItemContainer,
  SellerItemInfoWrapper,
} from '../styledComponents';

const SellerItemCardSkeleton = () => (
  <ItemContainer>
    <Box width="auto">
      <Skeleton height="200px" width="100%" {...SkeletonColors} />
    </Box>
    <SellerItemInfoWrapper>
      <Skeleton height="20px" width="60%" mb={4} {...SkeletonColors} />
      <Flex align="center" mb={4}>
        <SkeletonCircle size="50px" {...SkeletonColors} />
        <Box ml={4}>
          <Skeleton height="16px" width="100px" mb={2} {...SkeletonColors} />
          <Skeleton height="12px" width="80px" {...SkeletonColors} />
        </Box>
      </Flex>
      <BadgesContainer>
        <Box mr={4}>
          <Skeleton height="25px" width="70px" {...SkeletonColors} />
        </Box>
        <Box>
          <Skeleton height="25px" width="70px" {...SkeletonColors} />
        </Box>
      </BadgesContainer>
    </SellerItemInfoWrapper>
  </ItemContainer>
);

export default SellerItemCardSkeleton;
