import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

import {
  darkGreenButtonStyle,
  greenButtonStyle,
  outlinedButtonStyle,
  transparentButtonStyle,
  yellowButtonStyle,
} from './ButtonStyles';

interface PrimaryButtonProps extends ButtonProps {
  size?: 'small' | 'medium' | 'large';
  green?: boolean;
  yellow?: boolean;
  darkGreen?: boolean;
  transparent?: boolean;
  outlined?: boolean;
  isActive?: boolean;
}

const PrimaryButton = ({
  size,
  green,
  yellow,
  darkGreen,
  transparent,
  outlined,
  isActive,
  children,
  disabled,
  variant,
  sx,
  ...props
}: PrimaryButtonProps) => {
  let buttonSizeStyle = {};
  let buttonStyle = {};

  if (green) buttonStyle = greenButtonStyle;
  if (yellow) buttonStyle = yellowButtonStyle;
  if (darkGreen) buttonStyle = darkGreenButtonStyle;
  if (transparent) buttonStyle = transparentButtonStyle;
  if (outlined) buttonStyle = outlinedButtonStyle;

  const finalSx = { ...buttonStyle, ...buttonSizeStyle, ...sx };

  return (
    <Button variant={variant} disabled={disabled} {...props} sx={finalSx}>
      {children}
    </Button>
  );
};

export default PrimaryButton;
