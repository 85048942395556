import React from 'react';
import { Box, Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { SkeletonColors } from '../../../constants/colors';

const ChatSkeleton = () => (
  <ChatSkeletonWrapper>
    <SkeletonChatsContainer>
      {[...Array(5)].map((_, index) => (
        <SkeletonChatItem key={index}>
          <SkeletonCircle size="10" {...SkeletonColors} />
          <SkeletonText
            noOfLines={2}
            spacing="4"
            skeletonHeight="2"
            width="100%"
            {...SkeletonColors}
          />
        </SkeletonChatItem>
      ))}
    </SkeletonChatsContainer>

    <Box mt={4}>
      <Skeleton height="44px" borderRadius="12px" {...SkeletonColors} />
    </Box>
  </ChatSkeletonWrapper>
);

const ChatSkeletonWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px;
  flex: 1;
`;

const SkeletonChatsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 70vh;
  overflow-y: auto;
  margin-bottom: 16px;
`;

const SkeletonChatItem = styled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

export default ChatSkeleton;
