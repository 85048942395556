import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import styled from '@emotion/styled';

import HomePageImage from '../assets/png/HomePageImage.png';
import { Breakpoints } from '../constants/sizes';

const ImageCollage = () => {
  return (
    <ImageContainer>
      <HomePageContainer src={HomePageImage} alt="HomePageImage" />
    </ImageContainer>
  );
};

const ImageContainer = styled(Box)`
  position: relative;
  width: 55%;
  height: 100%;
  top: -100px;

  @media screen and (max-width: ${Breakpoints.Width800}) {
    top: unset;
  }
`;

const HomePageContainer = styled(Image)`
  height: auto;
  width: 100%;
`;

export default ImageCollage;
