import React, { useState } from 'react';
import Adjust from '@adjustcom/adjust-web-sdk';

import { AdjustContext } from '../../context/adjustContext';

type AdjustWrapperProps = {
  children: React.ReactNode;
};

export const AdjustWrapper = ({ children }: AdjustWrapperProps) => {
  let AdjustSdk;
  const [wasInitialised, setWasInitialised] = useState(false);

  if (!wasInitialised && typeof window !== 'undefined') {
    AdjustSdk = require('@adjustcom/adjust-web-sdk');

    const options: Adjust.InitOptions = {
      appToken: process.env.REACT_APP_ADJUST_TOKEN || '',
      environment:
        process.env.REACT_APP_ADJUST_ENV === 'production'
          ? 'production'
          : 'sandbox',
      logLevel: 'info',
    };

    AdjustSdk.initSdk(options);
    setWasInitialised(true);
  }

  return (
    <AdjustContext.Provider value={AdjustSdk}>
      {children}
    </AdjustContext.Provider>
  );
};
