import { EncoreColors } from '@Encore-FastStartup-Phase-Out/encore-shared';

import { WebAppColors } from '../../constants/colors';

export const greenButtonStyle = {
  '&.chakra-button': {
    borderRadius: '12px',
    bg: EncoreColors.Primary,
    color: EncoreColors.White,
    _hover: {
      bg: EncoreColors.Primary30,
    },
    _active: {
      bg: EncoreColors.Primary2,
    },
  },
};

export const yellowButtonStyle = {
  '&.chakra-button': {
    borderRadius: '12px',
    bg: WebAppColors.Info1,
    color: EncoreColors.White,
    _hover: {
      bg: WebAppColors.Info30,
    },
    _active: {
      bg: WebAppColors.Info30,
    },
  },
};

export const darkGreenButtonStyle = {
  '&.chakra-button': {
    bg: WebAppColors.Green2,
    color: EncoreColors.White,
    _hover: {
      bg: EncoreColors.Primary30,
    },
    _active: {
      bg: EncoreColors.Primary30,
    },
    borderRadius: '12px',
    gap: '2px',
  },
};

export const transparentButtonStyle = {
  '&.chakra-button': {
    bg: 'transparent',
    color: EncoreColors.White,
    _hover: {
      bg: EncoreColors.Primary30,
    },
    _active: {
      bg: EncoreColors.Primary2,
    },
  },
};

export const outlinedButtonStyle = {
  '&.chakra-button': {
    bg: 'transparent',
    color: EncoreColors.White,
    borderRadius: '12px',
    border: `1px solid ${WebAppColors.Gray50}`,
    _hover: {
      bg: EncoreColors.Primary30,
    },
    _active: {
      bg: EncoreColors.Primary2,
    },
  },
};
