import { observer } from 'mobx-react-lite';
import { Box } from '@chakra-ui/react';

import { useTranslate } from '../../hooks/useTranslate';
import { BodyText2, BodyTextLarge1 } from '../typography/BodyTexts';
import { Heading5 } from '../typography/Headlines';
import { WebAppColors } from '../../constants/colors';

import { FlexContainer, SellerStatsContainer } from './styledComponents';

type SellerInfoProps = {
  nrOfFollowers: number;
  nrOfFollowing: number;
  nrOfReviews: number;
  description?: string;
};

const SellerInfo = ({
  nrOfFollowers,
  nrOfFollowing,
  nrOfReviews,
  description,
}: SellerInfoProps) => {
  const t = useTranslate();

  return (
    <Box flex={2} maxW={'600px'}>
      <SellerStatsContainer>
        <FlexContainer>
          <Heading5>{nrOfFollowers}</Heading5>
          <BodyTextLarge1>{t('user.followers')}</BodyTextLarge1>
        </FlexContainer>
        <FlexContainer>
          <Heading5 fontWeight={'bold'}>{nrOfFollowing}</Heading5>
          <BodyTextLarge1>{t('user.following')}</BodyTextLarge1>
        </FlexContainer>
        <FlexContainer>
          <Heading5 fontWeight={'bold'}>{nrOfReviews}</Heading5>
          <BodyTextLarge1>{t('user.reviews')}</BodyTextLarge1>
        </FlexContainer>
      </SellerStatsContainer>
      <BodyText2 color={WebAppColors.Gray60}>{description}</BodyText2>
    </Box>
  );
};

export default observer(SellerInfo);
