import React, { useEffect, useState } from 'react';
import {
  Button,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { EncoreColors } from '@Encore-FastStartup-Phase-Out/encore-shared';

import PrimaryButton from '../buttons/PrimaryButton';
import EncoreLogo from '../../assets/png/encore-logo.png';
import { WebAppColors } from '../../constants/colors';
import { useTranslate } from '../../hooks/useTranslate';

type OpenInAppPopupProps = {
  linkToOpen: string;
};

const OpenInAppPopup = ({ linkToOpen }: OpenInAppPopupProps) => {
  const [hasUserInteracted, setHasUserInteracted] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const t = useTranslate();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!hasUserInteracted) {
        onOpen();
      }
    }, 100);
    return () => clearTimeout(timer);
  }, [onOpen, hasUserInteracted]);

  const handleOpenInApp = () => {
    window.location.href = linkToOpen;
  };

  const handleContinueOnWeb = () => {
    setHasUserInteracted(true);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleContinueOnWeb} isCentered>
      <ModalOverlay />
      <StyledModalContent>
        <ModalHeader>
          <Image src={EncoreLogo} alt="encore logo" width={100} />
        </ModalHeader>
        <ModalBody>
          <Text textAlign={'center'}>{t('common.openInAppDescription')}</Text>
        </ModalBody>
        <StyledModalFooter>
          <PrimaryButton
            green
            mr={3}
            onClick={handleOpenInApp}
            style={{
              borderRadius: '10px',
              padding: '8px 55px',
            }}
          >
            {t('common.openInApp')}
          </PrimaryButton>
          <Button variant="ghost" onClick={handleContinueOnWeb}>
            {t('common.continueOnWeb')}
          </Button>
        </StyledModalFooter>
      </StyledModalContent>
    </Modal>
  );
};

const StyledModalContent = styled(ModalContent)`
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  border-radius: 24px;
  border: 2px solid ${EncoreColors.White};
  background-color: ${WebAppColors.Black60};
  box-shadow:
    0 12px 0 0 ${WebAppColors.GreenBlur},
    0 27px 0 0 ${WebAppColors.GreenBlur};
  backdrop-filter: blur(46px);
  min-height: 40%;
`;

const StyledModalFooter = styled(ModalFooter)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
`;

export default OpenInAppPopup;
