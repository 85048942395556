import React from 'react';
import { Box, Divider, Text, VStack } from '@chakra-ui/react';
import { EncoreColors } from '@Encore-FastStartup-Phase-Out/encore-shared';
import { Helmet } from 'react-helmet';

import { HomeLayout } from '../components/layouts/HomeLayout';
import { useTranslate } from '../hooks/useTranslate';
import { MediumHeading1 } from '../components/typography/Headlines';
import { InfoCard } from '../components/common/InfoCard';
import { SimpleLayout } from '../components/layouts/SimpleLayout';

export const TermsScreen = () => {
  const t = useTranslate();
  const searchParams = new URLSearchParams(location.search);
  const app = searchParams.get('app') === 'true';
  const Layout = app ? SimpleLayout : HomeLayout;

  return (
    <Layout>
      <Helmet>
        <title>{t('terms.termsTitle')}</title>
      </Helmet>
      <Box p={8} maxW="container.lg" mx="auto">
        <VStack spacing={8} align="start">
          <MediumHeading1>{t('terms.termsTitle')}</MediumHeading1>
          <Text alignSelf={'end'} fontSize="sm" color={EncoreColors.Primary30}>
            {t('terms.lastUpdate')}
          </Text>
          <Text fontSize="md">{t('terms.welcome')}</Text>
          <Text fontSize="md" mb={6}>
            {t('terms.description')}
          </Text>
          <Divider />
          <InfoCard
            title={t('terms.requirementsTitle')}
            description={t('terms.requirementsDescription')}
          />
          <InfoCard
            title={t('terms.descriptionOfEncore')}
            description={t('terms.platformDescription')}
          />
          <InfoCard
            title={t('terms.taxes')}
            description={t('terms.taxesContent')}
          />
          <InfoCard
            title={t('terms.fees')}
            description={t('terms.feesContent')}
          />
          <InfoCard
            title={t('terms.shipping')}
            description={t('terms.shippingContent')}
          />
          <InfoCard
            title={t('terms.copyrightInfringement')}
            description={t('terms.copyrightInfringementContent')}
          />
          <InfoCard
            title={t('terms.indemnity')}
            description={t('terms.indemnityContent')}
          />
          <InfoCard
            title={t('terms.changesToTerms')}
            description={t('terms.changesToTermsContent')}
          />
        </VStack>
      </Box>
    </Layout>
  );
};
