import React, { useEffect } from 'react';
import {
  Box,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  useMediaQuery,
} from '@chakra-ui/react';
import { EncoreColors } from '@Encore-FastStartup-Phase-Out/encore-shared';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';

import { WebAppColors } from '../../constants/colors';
import { useTranslate } from '../../hooks/useTranslate';
import EncoreLogo from '../../assets/png/encore-logo.png';
import { Heading3 } from '../typography/Headlines';
import { BodyText1, BodyText2 } from '../typography/BodyTexts';
import { ReactComponent as QRCode } from '../../assets/svg/qr-code.svg';
import { useStore } from '../../hooks/useStore';
import { Breakpoints } from '../../constants/sizes';
import PrimaryButton from '../buttons/PrimaryButton';
import MixpanelService from '../../services/MixpanelService';

const DownloadAppPopup = () => {
  const t = useTranslate();
  const {
    uiStore: { isDownloadPopupOpen, closeDownloadPopup },
  } = useStore();

  const [isWidth450] = useMediaQuery([`(max-width: ${Breakpoints.Width450})`]);

  const handleButtonClick = () => {
    const adjustLink = process.env.REACT_APP_ADJUST_LINK;
    if (adjustLink) {
      window.location.href = adjustLink;
    }
    MixpanelService.trackClickOnOpenAppMobile();
  };

  useEffect(() => {
    if (isDownloadPopupOpen) {
      MixpanelService.downloadAppPopUpShown();
    }
  }, [isDownloadPopupOpen]);

  return (
    <Modal isOpen={isDownloadPopupOpen} onClose={closeDownloadPopup} isCentered>
      <ModalOverlay />
      <StyledModalContent>
        <Image
          src={EncoreLogo}
          alt="encore logo"
          width={{ base: 100, md: 140 }}
        />
        {isWidth450 ? (
          <PrimaryButton
            green
            onClick={handleButtonClick}
            style={{
              padding: '24px 24px',
              borderRadius: '12px',
            }}
          >
            <BodyText2 fontWeight="700">{t(`common.continueToApp`)}</BodyText2>
          </PrimaryButton>
        ) : (
          <>
            <Box>
              <Heading3 textAlign={'center'}>
                {t('common.downloadTheApp')}
                <br />
                {t('common.forThe')}
                <span style={{ color: EncoreColors.Primary }}>
                  {' '}
                  {t('common.fullExperience')}
                </span>
              </Heading3>
            </Box>
            <QRContainer>
              <Box
                as={QRCode}
                padding={'10px'}
                borderRadius={'12px'}
                w={{ base: '150px', md: '180px' }}
                backgroundColor={WebAppColors.GrayBackground}
              />
              <BodyText1>{t('common.scanQRCode')}</BodyText1>
            </QRContainer>
          </>
        )}
      </StyledModalContent>
    </Modal>
  );
};

const StyledModalContent = styled(ModalContent)`
  padding: 32px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 32px;
  border-radius: 24px;
  border: 2px solid ${EncoreColors.White};
  background-color: ${WebAppColors.Black60};
  box-shadow:
    0 12px 0 0 ${WebAppColors.GreenBlur},
    0 27px 0 0 ${WebAppColors.GreenBlur};
  backdrop-filter: blur(46px);
  width: 90vw;
  max-width: 615px;

  @media (min-width: ${Breakpoints.Width650}) {
    padding: 48px 24px;
    gap: 48px;
  }

  @media (max-width: ${Breakpoints.Width1080}) {
    width: 80vw;
  }

  @media (max-width: ${Breakpoints.Width650}) {
    width: 100vw;
    max-width: 90vw;
    padding: 24px;
  }

  @media (max-width: ${Breakpoints.Width450}) {
    width: 100vw;
    max-width: 70vw;
    padding: 24px;
  }
`;

const QRContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;
`;

export default observer(DownloadAppPopup);
