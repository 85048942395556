import React from 'react';
import { Box, Flex } from '@chakra-ui/react';

import { WebAppColors } from '../../constants/colors';
import { ReactComponent as EncoreLogo } from '../../assets/svg/small-logo.svg';
import { BodyText1, BodyText2 } from '../typography/BodyTexts';
import PrimaryButton from '../buttons/PrimaryButton';
import { useTranslate } from '../../hooks/useTranslate';
import MixpanelService from '../../services/MixpanelService';

const MobileBanner = () => {
  const t = useTranslate();

  const handleButtonClick = () => {
    const adjustLink = process.env.REACT_APP_ADJUST_LINK;
    if (adjustLink) {
      window.location.href = adjustLink;
    }
    MixpanelService.trackClickOnOpenAppMobile();
  };

  return (
    <Box
      as="header"
      bg={WebAppColors.DarkBackground}
      padding="10px 22px"
      position="sticky"
      bottom="0"
      zIndex="2024"
    >
      <Flex justify="space-between" align="center">
        <Flex align="center" gap={3}>
          <Box as={EncoreLogo} width={10} height={10} />
          <BodyText2 fontWeight="700">{t('common.openInTheApp')}</BodyText2>
        </Flex>
        <PrimaryButton
          green
          style={{
            borderRadius: '20px',
            padding: '10px 20px',
          }}
          onClick={handleButtonClick}
        >
          <BodyText1 fontWeight="700">{t('common.encoreApp')}</BodyText1>
        </PrimaryButton>
      </Flex>
    </Box>
  );
};

export default MobileBanner;
