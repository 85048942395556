import React from 'react';
import {
  Box,
  Center,
  Flex,
  Image,
  Text,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import { EncoreColors } from '@Encore-FastStartup-Phase-Out/encore-shared';

import HomeBottomImage from '../../assets/png/HomeBottomImage.png';
import SmallHomeBottomImage from '../../assets/png/small-footer.png';
import { StyledDiv2 } from '../typography/Headlines';
import { useTranslate } from '../../hooks/useTranslate';
import { BodyText1, BodyText2 } from '../typography/BodyTexts';
import PrimaryButton from '../buttons/PrimaryButton';
import { ReactComponent as QRCode } from '../../assets/svg/green-qr-code.svg';
import { useStore } from '../../hooks/useStore';
import { Breakpoints } from '../../constants/sizes';
import MixpanelService from '../../services/MixpanelService';

const DownloadAppCard = () => {
  const t = useTranslate();
  const {
    uiStore: { openDownloadPopup },
  } = useStore();

  const [isWidth800, isWidth450] = useMediaQuery([
    `(max-width: ${Breakpoints.Width800})`,
    `(max-width: ${Breakpoints.Width450})`,
  ]);

  const onOpenAppClick = () => {
    const adjustLink = process.env.REACT_APP_ADJUST_LINK;
    if (adjustLink && isWidth450) {
      window.location.href = adjustLink;
      MixpanelService.trackClickOnOpenAppMobile();
      return;
    }
    openDownloadPopup();
  };

  return (
    <Flex
      justifyContent={'space-between'}
      backgroundColor={EncoreColors.Primary}
      gap={'52px'}
      padding={isWidth800 ? '20px 0' : '80px 0'}
      position="relative"
    >
      {!isWidth450 ? (
        <Box
          flex={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Image src={HomeBottomImage} alt="HomeBottomImage" />
        </Box>
      ) : (
        <Box position="absolute" width="100%" bottom="0">
          <Image src={SmallHomeBottomImage} alt="SmallHomeImage" width="100%" />
        </Box>
      )}
      <Center flex={1} justifyContent={'center'} padding={'32px'}>
        <VStack
          spacing={!isWidth800 ? 4 : 6}
          p={8}
          maxW="800"
          textAlign="center"
        >
          <StyledDiv2 textAlign={'center'}>
            {t('homepage.downloadAppFullExperience')}
          </StyledDiv2>
          <Box w={'70%'}>
            <BodyText1 textAlign={'center'}>
              {t('homepage.downloadAppFullExperienceSubtext')}
            </BodyText1>
          </Box>
          {!isWidth800 && (
            <>
              <Box w={'160px'} as={QRCode} />
              <Text color={EncoreColors.White}>{t('common.scanQRCode')}</Text>
            </>
          )}

          <PrimaryButton
            bg={EncoreColors.Black}
            color={EncoreColors.White}
            _hover={{ bg: EncoreColors.DarkBackground }}
            onClick={onOpenAppClick}
            style={{
              padding: '24px 24px',
              borderRadius: '12px',
            }}
          >
            <BodyText2 fontWeight="700">
              {t(`common.${isWidth450 ? 'continueToApp' : 'downloadApp'}`)}
            </BodyText2>
          </PrimaryButton>
        </VStack>
      </Center>
    </Flex>
  );
};

export default DownloadAppCard;
