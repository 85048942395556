import { Language } from '@Encore-FastStartup-Phase-Out/encore-shared';
import { makeAutoObservable } from 'mobx';

class AppState {
  public language: Language;

  constructor() {
    this.language = Language.English;

    makeAutoObservable(this);
  }
}

export default AppState;
