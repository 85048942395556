import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  TabProps,
  Tabs,
  useMediaQuery,
} from '@chakra-ui/react';
import { EncoreColors } from '@Encore-FastStartup-Phase-Out/encore-shared';

import { Heading3 } from '../typography/Headlines';
import { useStore } from '../../hooks/useStore';
import { useTranslate } from '../../hooks/useTranslate';
import { Breakpoints } from '../../constants/sizes';

import ListingsTabPanel from './ListingsTabPanel';

interface CustomTabProps extends TabProps {
  children: React.ReactNode;
}

const CustomTab: React.FC<CustomTabProps> = ({ children, ...rest }) => {
  const isSelected = rest['aria-selected'] === 'true';
  const [isWidth650] = useMediaQuery([`(max-width: ${Breakpoints.Width650})`]);

  return (
    <Tab
      {...rest}
      _selected={{ color: EncoreColors.Primary }}
      sx={rest.tabIndex === 0 && isWidth650 ? { paddingLeft: 0 } : undefined}
    >
      <Heading3
        fontSize="30px"
        color={isSelected ? EncoreColors.Primary : 'inherit'}
      >
        {children}
      </Heading3>
    </Tab>
  );
};

const SellerListingsTabs: React.FC = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const {
    listingStore: {
      userSoldListings,
      userActiveListings,
      getUserActiveListings,
      getUserSoldListings,
      loading,
    },
    userStore: { currentUser },
  } = useStore();
  const t = useTranslate();

  const [isWidth650] = useMediaQuery([`(max-width: ${Breakpoints.Width650})`]);

  const translateXActive = isWidth650 ? '30%' : '100%';
  const translateXSold = isWidth650 ? '45%' : '75%';

  return (
    <Tabs
      position="relative"
      variant="unstyled"
      onChange={(index) => setSelectedIndex(index)}
    >
      <TabList>
        <CustomTab tabIndex={0}>{t('user.active')}</CustomTab>
        <CustomTab tabIndex={1}>{t('user.sold')}</CustomTab>
      </TabList>
      <TabIndicator
        height="3px"
        bg={EncoreColors.Primary}
        borderRadius="3px"
        position="absolute"
        transform={`translateX(${selectedIndex === 0 ? translateXActive : translateXSold})`}
        width="38px !important"
      />
      <TabPanels>
        <TabPanel p={0}>
          <ListingsTabPanel
            listings={userActiveListings}
            fetchListings={getUserActiveListings}
            userId={currentUser?.id || ''}
            isLoading={loading}
          />
        </TabPanel>
        <TabPanel p={0}>
          <ListingsTabPanel
            listings={userSoldListings}
            fetchListings={getUserSoldListings}
            userId={currentUser?.id || ''}
            isLoading={loading}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default observer(SellerListingsTabs);
