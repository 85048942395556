import React from 'react';
import { IconButton, IconButtonProps } from '@chakra-ui/react';

import {
  darkGreenButtonStyle,
  greenButtonStyle,
  outlinedButtonStyle,
  transparentButtonStyle,
} from './ButtonStyles';

interface PrimaryButtonProps extends IconButtonProps {
  size?: 'small' | 'medium' | 'large';
  green?: boolean;
  darkGreen?: boolean;
  transparent?: boolean;
  outlined?: boolean;
  isActive?: boolean;
  icon:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined;
}

const PrimaryIconButton = ({
  size,
  green,
  darkGreen,
  transparent,
  outlined,
  isActive,
  children,
  disabled,
  variant,
  sx,
  icon,
  ...props
}: PrimaryButtonProps) => {
  let buttonSizeStyle = {};
  let buttonStyle = {};

  if (green) buttonStyle = greenButtonStyle;
  if (darkGreen) buttonStyle = darkGreenButtonStyle;
  if (transparent) buttonStyle = transparentButtonStyle;
  if (outlined) buttonStyle = outlinedButtonStyle;

  const finalSx = { ...buttonStyle, ...buttonSizeStyle, ...sx };

  return (
    <IconButton
      variant={variant}
      disabled={disabled}
      {...props}
      sx={finalSx}
      icon={icon}
    />
  );
};

export default PrimaryIconButton;
