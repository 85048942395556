"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EncoreColors = void 0;
exports.EncoreColors = {
    PrimaryBlack: '#1C1C1E',
    TextBlack: '#020202',
    Primary: '#00BA5C',
    Primary2: '#003319',
    Primary3: '#132217',
    Primary30: 'rgba(0, 186, 92, 0.3)',
    Primary150: 'rgba(0, 186, 92, 0.15)',
    Gray: '#2C2C2E',
    Gray500: '#697586',
    Gray700: '#2C2E2C',
    MediumGray: '#979797',
    LightGray: '#8E8E93',
    LightGray50: '#E9E9E9',
    LightGray100: '#B1ADAD',
    LightGray200: '#D9D9D9',
    LightGray300: '#2C2C2E61',
    LightGray400: '#B2B2BB',
    GreyText: '#AEAEB2',
    GreyBackground: '#3A3A3C',
    DarkBackground: '#222223',
    GreyLine: '#636366',
    White: '#fff',
    Black: '#000',
    Success: '#8BBA00',
    Error200: '#220E0E',
    Error: '#E96161',
    Error500: '#FF3D3D',
    Error600: '#FF5050',
    Blue: '#1A89FF',
    Info100: '#FFBA09',
    Info200: '#FFBF00',
    Info300: '#FBBC05',
    Info500: '#FFEA00',
    DarkYellowBackground: '#795800',
    YellowBackground: '#E0A917'
};
