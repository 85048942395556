import React from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from '../../hooks/useStore';
import SellerItemCard from '../cards/SellerItemCard';
import { useTranslate } from '../../hooks/useTranslate';
import SellerItemCardSkeleton from '../cards/skeletons/SellerItemCardSkeleton';

import {
  MoreText,
  SellerItemsContainer,
  SellerItemsListWrapper,
} from './styledComponents';

interface SellerListingsProps {
  loading?: boolean;
}

const SellerListings = ({ loading }: SellerListingsProps) => {
  const {
    listingStore: { userActiveListings, currentListing },
  } = useStore();

  const t = useTranslate();

  return (
    <SellerItemsListWrapper>
      <MoreText>{`${t('auction.moreFrom')} '${currentListing?.user.userName}'`}</MoreText>
      <SellerItemsContainer>
        {loading
          ? Array.from({ length: 5 }).map((_, index) => (
              <SellerItemCardSkeleton key={index} />
            ))
          : userActiveListings.map((listing) => (
              <SellerItemCard key={listing.id} listing={listing} />
            ))}
      </SellerItemsContainer>
    </SellerItemsListWrapper>
  );
};

export default observer(SellerListings);
