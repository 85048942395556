import React from 'react';
import { Box, Flex } from '@chakra-ui/react';

import Header from '../common/Header';
import Footer from '../common/Footer';

type HomeLayoutProps = {
  children: React.ReactNode;
};

export const HomeLayout = ({ children }: HomeLayoutProps) => (
  <Box minHeight="100vh" position="relative">
    <Header />
    <Flex minHeight="60vh" width="100vw">
      {children}
    </Flex>
    <Footer />
  </Box>
);
