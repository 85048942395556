import dayjs from '../utils/configuredDayjs';

export const formatTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

  return `${minutes}:${formattedSeconds}`;
};

export const getDaysAndWeekNames = (): {
  weekNames: string[];
  dates: string[];
} => {
  const dates: string[] = [];
  const weekNames: string[] = [];
  let date = dayjs();

  for (let i = 0; i <= 6; i++) {
    if (i > 0) {
      date = date.add(1, 'day');
    }
    dates.push(date.format('YYYY-MM-DD'));
    weekNames.push(date.format('ddd'));
  }

  return { weekNames, dates };
};

export const calculateTimeDifference = (
  endTime: number,
  format?: 'dhms' | 'hms' | 'ms' | 'hm' | 'dh',
) => {
  let currentDate = new Date();

  let futureDate = new Date(
    endTime - currentDate.getTimezoneOffset() * 60 * 1000,
  );

  let difference =
    futureDate.getTime() -
    (currentDate.getTime() - currentDate.getTimezoneOffset() * 60 * 1000);

  let d = Math.floor(difference / (1000 * 60 * 60 * 24));
  let h = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  let m = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  let s = Math.floor((difference % (1000 * 60)) / 1000);

  let timerString = '';

  if (format) {
    for (let i = 0; i < format.length; i++) {
      switch (format[i]) {
        case 'd':
          if (d > 0) timerString += `${d}d `;
          break;
        case 'h':
          if (h > 0) timerString += `${h}h `;
          break;
        case 'm':
          if (m > 0) timerString += `${m}m `;
          break;
        case 's':
          if (s > 0) timerString += `${s}s `;
          break;
        default:
          break;
      }
    }
  } else {
    timerString = `${d}d ${h}h ${m}m ${s}s`;
  }

  return { formatted: timerString.trim(), timeLeft: difference };
};
