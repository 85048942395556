import { makeAutoObservable } from 'mobx';

export class UIStore {
  isDownloadPopupOpen: boolean = false;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  openDownloadPopup() {
    this.isDownloadPopupOpen = true;
  }

  closeDownloadPopup() {
    this.isDownloadPopupOpen = false;
  }
}
