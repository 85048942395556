import React from 'react';
import { Box, Flex } from '@chakra-ui/react';

type SimpleLayout = {
  children: React.ReactNode;
};

export const SimpleLayout = ({ children }: SimpleLayout) => (
  <Box minHeight="100vh" position="relative">
    <Flex minHeight="60vh" width="100vw">
      {children}
    </Flex>
  </Box>
);
