import { IUser } from '@Encore-FastStartup-Phase-Out/encore-shared';

import { endpoints } from '../constants/endpoints';

import { BaseApi } from './index';

export class UserApi extends BaseApi<IUser> {
  constructor() {
    super(endpoints.users.base);
  }

  async getUserById(id: string) {
    return this.get(endpoints.users.byId(id));
  }

  async getUserByUsername(username: string) {
    return this.get(endpoints.users.byUsername(username));
  }
}
