import styled from '@emotion/styled';
import { EncoreColors } from '@Encore-FastStartup-Phase-Out/encore-shared';

import { Breakpoints } from '../../constants/sizes';

interface BodyProps {
  fontWeight?: string;
  color?: string;
  letterSpacing?: string;
  textTransform?: string;
  fontFamily?: string;
  lineHeight?: string;
  fontStyle?: string;
  cursor?: string;
  textAlign?: string;
}

const BaseBodyText = styled.div`
  font-family: 'Manrope Regular', serif;
  font-style: normal;
  font-weight: normal;
  color: ${EncoreColors.White};
`;

const BodyTextLarge1 = styled(BaseBodyText)<BodyProps>`
  font-size: 24px;
  line-height: ${(props) => props.lineHeight || '28px'};
  letter-spacing: -0.18px;
  font-weight: ${(props) => props.fontWeight};
  color: ${(props) => props.color};
  font-style: ${(props) => props.fontStyle};
  text-align: ${(props) => props.textAlign};

  @media (max-width: 1080px) {
    font-size: 14px;
    line-height: ${(props) => props.lineHeight || '20px'};
  }
`;

const BodyTextLarge2 = styled(BaseBodyText)<BodyProps>`
  font-size: 20px;
  line-height: ${(props) => props.lineHeight || '28px'};
  letter-spacing: -0.18px;
  font-weight: ${(props) => props.fontWeight};
  color: ${(props) => props.color};
  font-style: ${(props) => props.fontStyle};
  text-align: ${(props) => props.textAlign};

  @media (max-width: ${Breakpoints.Width1080}) {
    font-size: 14px;
    line-height: ${(props) => props.lineHeight || '20px'};
  }
`;

const BodyText1 = styled(BaseBodyText)<BodyProps>`
  font-size: 18px;
  line-height: ${(props) => props.lineHeight || '28px'};
  letter-spacing: -0.18px;
  font-weight: ${(props) => props.fontWeight};
  color: ${(props) => props.color};
  font-style: ${(props) => props.fontStyle};
  text-align: ${(props) => props.textAlign};

  @media (max-width: ${Breakpoints.Width1080}) {
    font-size: 14px;
    line-height: ${(props) => props.lineHeight || '20px'};
  }
`;

const BodyText2 = styled(BaseBodyText)<BodyProps>`
  font-size: 16px;
  line-height: ${(props) => props.lineHeight || '24px'};
  letter-spacing: ${(props) => props.letterSpacing || '-0.18px'};
  font-weight: ${(props) => props.fontWeight};
  color: ${(props) => props.color};
  font-style: ${(props) => props.fontStyle};
`;

const BodyText3 = styled(BaseBodyText)<BodyProps>`
  font-size: 14px;
  line-height: ${(props) => props.lineHeight || '20px'};
  letter-spacing: ${(props) => props.letterSpacing || '-0.18px'};
  font-weight: ${(props) => props.fontWeight};
  color: ${(props) => props.color};
  text-transform: ${(props) => props.textTransform};
  font-family: ${(props) => props.fontFamily};
  font-style: ${(props) => props.fontStyle};
  cursor: ${(props) => props.cursor};

  @media (max-width: ${Breakpoints.Width1080}) {
    font-size: 12px;
    line-height: ${(props) => props.lineHeight || '18px'};
  }
`;

const BodyText4 = styled(BaseBodyText)<BodyProps>`
  font-size: 12px;
  line-height: ${(props) => props.lineHeight || '14px'};
  letter-spacing: ${(props) => props.letterSpacing || '0.06px'};
  font-weight: ${(props) => props.fontWeight || 400};
  color: ${(props) => props.color};
  font-style: ${(props) => props.fontStyle};
  text-transform: ${(props) => props.textTransform};
`;

const BodyText5 = styled(BaseBodyText)<BodyProps>`
  font-size: 10px;
  line-height: ${(props) => props.lineHeight || '14px'};
  letter-spacing: ${(props) => props.letterSpacing || '0.06px'};
  font-weight: ${(props) => props.fontWeight || 400};
  color: ${(props) => props.color};
  font-style: ${(props) => props.fontStyle};
`;

export {
  BodyTextLarge1,
  BodyTextLarge2,
  BodyText1,
  BodyText2,
  BodyText3,
  BodyText4,
  BodyText5,
};
