import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';

import { UserAuctionsGridContainer } from '../../screens/styledComponents';
import ItemCard from '../cards/ItemCard';
import { CompleteListing } from '../../models/Listing';
import { useTranslate } from '../../hooks/useTranslate';
import { ItemCardSkeleton } from '../cards/skeletons/ItemCardSkeleton';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';

type ListingsTabPanelProps = {
  fetchListings: (
    userId: string,
    pageNo?: number,
    limit?: number,
  ) => Promise<{
    items: CompleteListing[];
    count: number;
  }>;
  listings: CompleteListing[];
  userId: string;
  isLoading: boolean;
};

const ListingsTabPanel = ({
  listings,
  fetchListings,
  userId,
  isLoading,
}: ListingsTabPanelProps) => {
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const t = useTranslate();

  const fetchMoreListings = async () => {
    const result = await fetchListings(userId, page, 20);
    if (result.items.length > 0) {
      setPage((prevPage) => prevPage + 1);
      setHasMore(listings.length + result.items.length < result.count);
    } else {
      setHasMore(false);
    }

    setInitialLoad(false);
  };

  const { sentinelRef } = useInfiniteScroll({
    fetchMore: fetchMoreListings,
    hasMore,
  });

  return (
    <Box minH={'400px'} pt={'20px'}>
      {!isLoading && !initialLoad && listings.length === 0 && (
        <Box textAlign="center" mt="20px">
          {t('user.noListingsFound')}
        </Box>
      )}
      <UserAuctionsGridContainer>
        {isLoading && initialLoad ? (
          <>
            {Array.from({ length: 5 }).map((_, i) => (
              <ItemCardSkeleton key={i} />
            ))}
          </>
        ) : (
          listings.map((listing, index) => (
            <ItemCard key={`${listing.id}-${index}`} listing={listing} />
          ))
        )}
      </UserAuctionsGridContainer>

      <div ref={sentinelRef} style={{ height: '20px', marginTop: '20px' }} />
    </Box>
  );
};

export default observer(ListingsTabPanel);
