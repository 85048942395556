import React from 'react';
import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { WebAppColors } from '../constants/colors';

const BlurCircle = styled(Box)`
  position: absolute;
  width: 40vw;
  height: 40vw;
  border-radius: 50%;
  background: ${WebAppColors.Green1};
  filter: blur(8vw);
  z-index: 0;
`;

const BlurCircles = () => {
  return (
    <>
      <BlurCircle left="-20%" bottom="30%" />
      <BlurCircle left="-20%" top="25%" />
      <BlurCircle right="-20%" top="30%" />
      <BlurCircle right="-20%" bottom="20%" />
      <BlurCircle left="30%" bottom="0" />
    </>
  );
};

export default BlurCircles;
