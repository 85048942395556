import React from 'react';
import { Box, Flex, useMediaQuery } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { EncoreColors } from '@Encore-FastStartup-Phase-Out/encore-shared';
import { Helmet } from 'react-helmet';

import {
  Heading1,
  SmallHeading2,
  StyledDiv5,
} from '../components/typography/Headlines';
import { useTranslate } from '../hooks/useTranslate';
import PrimaryButton from '../components/buttons/PrimaryButton';
import AuctionsPreview from '../components/itemBars/AuctionsPreview';
import { useStore } from '../hooks/useStore';
import { Breakpoints } from '../constants/sizes';
import MixpanelService from '../services/MixpanelService';

import {
  HomeScreenWrapper,
  InfoWrapper,
  PrimaryTextWrapper,
} from './styledComponents';
import ImageCollage from './ImageCollage';

const HomeScreen = () => {
  const t = useTranslate();
  const {
    uiStore: { openDownloadPopup },
  } = useStore();

  const onOpenAppClick = () => {
    const adjustLink = process.env.REACT_APP_ADJUST_LINK;
    if (adjustLink && isWidth450) {
      window.location.href = adjustLink;
      MixpanelService.trackClickOnOpenAppMobile();
      return;
    }
    openDownloadPopup();
  };

  const [isWidth450, isWidth650] = useMediaQuery([
    `(max-width: ${Breakpoints.Width450})`,
    `(max-width: ${Breakpoints.Width650})`,
  ]);

  return (
    <Box>
      <Helmet>
        <title>
          {t('homepage.buySell') +
            ' ' +
            t('homepage.collectibles') +
            ' ' +
            t('homepage.withStories')}
        </title>
      </Helmet>
      <Flex width="100vw">
        <HomeScreenWrapper>
          <InfoWrapper>
            <PrimaryTextWrapper>
              <Heading1>
                {t('homepage.buySell')}
                <div style={{ color: EncoreColors.Primary }}>
                  {t('homepage.collectibles')}
                </div>
                {t('homepage.withStories')}
              </Heading1>
            </PrimaryTextWrapper>
            <SmallHeading2>{t('homepage.description')}</SmallHeading2>
            <Flex justifyContent={'flex-start'}>
              <PrimaryButton
                mt={5}
                green
                variant="solid"
                padding={'27px 35px'}
                onClick={onOpenAppClick}
              >
                <StyledDiv5>
                  {t(`common.${isWidth450 ? 'continueToApp' : 'downloadApp'}`)}
                </StyledDiv5>
              </PrimaryButton>
            </Flex>
          </InfoWrapper>
          {!isWidth650 && <ImageCollage />}
        </HomeScreenWrapper>
      </Flex>
      <AuctionsPreview />
    </Box>
  );
};

export default observer(HomeScreen);
