import i18next, { i18n } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { makeAutoObservable, reaction } from 'mobx';

import en from '../locales/en.json';

import AppState from './AppState';

class LocaleStore {
  readonly appState: AppState;
  readonly i18next: i18n;

  constructor(appState: AppState) {
    this.appState = appState;
    this.i18next = i18next;

    reaction(
      () => this.appState.language,
      () => this.i18next.changeLanguage(this.appState.language)
    )

    makeAutoObservable(this);
    this.initialize()
  }

  initialize() {
    this.i18next.use(initReactI18next).init({
      debug: false,
      compatibilityJSON: 'v3',
      lng: 'en',
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
      resources: {
        en,
      },
      returnNull: false,
    });
  }
}

export default LocaleStore;
