import React from 'react';
import { Box, Image, useMediaQuery, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router';

import HomePageImage from '../assets/png/HomePageImage.png';
import {
  Heading1,
  SmallHeading2,
  SmallHeading3,
} from '../components/typography/Headlines';
import PrimaryButton from '../components/buttons/PrimaryButton';
import { useTranslate } from '../hooks/useTranslate';
import { Routes } from '../router/routes';
import { Breakpoints } from '../constants/sizes';

const NotFoundScreen = () => {
  const t = useTranslate();
  const navigate = useNavigate();
  const [isWidth800] = useMediaQuery([`(max-width: ${Breakpoints.Width800})`]);

  const goToHome = () => {
    navigate(Routes.Home);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      p={10}
    >
      <VStack align="start" spacing={8}>
        <Heading1>{t('notFound.title')}</Heading1>
        <SmallHeading2>{t('notFound.description')}</SmallHeading2>
        <SmallHeading3>{t('notFound.checkPage')}</SmallHeading3>
        <PrimaryButton green variant="solid" onClick={goToHome}>
          {t('notFound.backToHomepage')}
        </PrimaryButton>
      </VStack>

      {!isWidth800 && (
        <Box>
          <Image src={HomePageImage} alt="404 Not Found" />
        </Box>
      )}
    </Box>
  );
};

export default NotFoundScreen;
