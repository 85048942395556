import React from 'react';
import { Box } from '@chakra-ui/react';
import Lottie from 'react-lottie';

import loadingAnimation from '../../assets/animations/encore_loading.json';

interface LoadingAnimationProps {
  loading: boolean;
}

const LoadingAnimation = ({ loading }: LoadingAnimationProps) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  if (!loading) {
    return null;
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="400px"
    >
      <Lottie options={defaultOptions} height={200} width={200} />
    </Box>
  );
};

export default LoadingAnimation;
