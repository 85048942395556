"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SAVE_ADDRESS_DTO = exports.CREATE_LISTING_DTO = void 0;
var enums_1 = require("../enums");
exports.CREATE_LISTING_DTO = {
    title: '',
    description: '',
    price: 0,
    currency: 'USD',
    isShippingFree: false,
    video: '',
    trimmedVideo: '',
    listingType: 'default',
    inStock: 1,
    shippingCharges: 0,
    shippingCurrency: 'USD',
    startingBid: 0,
    timezone: '',
    auctionEndDate: '',
    auctionEndTime: '',
    packagingType: '',
    weight: 0,
    massUnit: '',
    width: 0,
    height: 0,
    length: 0,
    endTime: new Date().getTime(),
};
exports.SAVE_ADDRESS_DTO = {
    firstName: '',
    lastName: '',
    addressLineOne: '',
    addressLineTwo: '',
    city: '',
    country: '',
    state: '',
    zipCode: '',
    countryCode: '',
    phoneNumber: '',
    type: enums_1.AddressType.DEFAULT,
};
