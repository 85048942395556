import React, { useEffect, useState } from 'react';
import { Box, Icon } from '@chakra-ui/react';
import { FiClock } from 'react-icons/fi';
import {
  EncoreColors,
  IAuctionMessage,
  ListingType,
} from '@Encore-FastStartup-Phase-Out/encore-shared';
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react-lite';

import { BodyText3, BodyText4 } from '../typography/BodyTexts';
import { CompleteListing } from '../../models/Listing';
import { useTranslate } from '../../hooks/useTranslate';
import { images } from '../images/images';
import { ReactComponent as Star } from '../../assets/svg/star.svg';
import { Routes } from '../../router/routes';
import { useStore } from '../../hooks/useStore';
import { ReactComponent as PresentIcon } from '../../assets/svg/present.svg';
import { WebAppColors } from '../../constants/colors';
import { getSecureUrl } from '../../utils/secureUrl';
import MixpanelService from '../../services/MixpanelService';

import {
  AuctionCardBadge,
  CardBadge,
  CardContainer,
  ImageContainer,
  ItemDetailsContainer,
  ItemImage,
  ItemTitle,
  RatingContainer,
  SpaceBetweenContainer,
  UserImage,
  UserImageContainer,
  UserImageWrapper,
} from './styledComponents';
import Timer from './Timer';

interface ItemCardProps {
  listing: CompleteListing;
}

const ItemCard = ({ listing }: ItemCardProps) => {
  const {
    listingStore: { setCurrentListing },
  } = useStore();
  const t = useTranslate();
  const navigate = useNavigate();

  const [priceToDisplay, setPriceToDisplay] = useState<number>(listing.price);
  const [bids, setBids] = useState<IAuctionMessage[]>([]);

  const onCardClick = () => {
    navigate(`${Routes.Listing}/${listing.id}`);
    setCurrentListing(listing);
    const mixpanelActions = {
      [ListingType.AUCTION]: () => MixpanelService.clickedOnAuction(listing.id),
      [ListingType.GIVEAWAY]: () =>
        MixpanelService.clickedOnGiveawayListing(listing.id),
      [ListingType.DEFAULT]: () =>
        MixpanelService.clickedOnBuyItNowListing(listing.id),
    };

    mixpanelActions[listing.listingType]?.();
  };

  useEffect(() => {
    if (listing.listingType === ListingType.AUCTION) {
      const bids = listing.auctionMessages;
      setBids(bids);
    }
  }, [listing]);

  useEffect(() => {
    if (bids.length > 0) {
      const currentBid = bids[bids.length - 1];
      if (currentBid.amount) setPriceToDisplay(currentBid.amount);
    }
  }, [bids]);

  return (
    <CardContainer key={`listing-${listing.id}`}>
      <ImageContainer onClick={onCardClick}>
        {listing.listingType !== ListingType.GIVEAWAY && (
          <AuctionCardBadge>
            <BodyText4>{`$${priceToDisplay}`}</BodyText4>
          </AuctionCardBadge>
        )}
        <ItemImage
          src={getSecureUrl(listing.thumbnail || '')}
          alt="Auction Item"
          height="auto"
        />
      </ImageContainer>

      <ItemDetailsContainer>
        <ItemTitle>{listing.title}</ItemTitle>

        <SpaceBetweenContainer>
          {listing.listingType === ListingType.AUCTION ? (
            <>
              <CardBadge $background={WebAppColors.Red15}>
                <Icon as={FiClock} />
                <Timer
                  endTime={listing.endTime ? listing.endTime : 0}
                  format="dhms"
                />
              </CardBadge>

              <CardBadge>
                <BodyText3
                  textTransform="none"
                  fontWeight="800"
                >{`🔥 ${listing.auctionMessages.length} ${t('common.bids')}`}</BodyText3>
              </CardBadge>
            </>
          ) : listing.listingType === ListingType.GIVEAWAY ? (
            <>
              <CardBadge $background={WebAppColors.Red15}>
                <Icon as={FiClock} />
                <Timer
                  endTime={listing.endTime ? listing.endTime : 0}
                  format="dhms"
                />
              </CardBadge>

              <CardBadge>
                <PresentIcon />
                <BodyText3
                  textTransform="none"
                  fontWeight="800"
                  color={EncoreColors.Info300}
                >
                  {t('auctions.giveaway')}
                </BodyText3>
              </CardBadge>
            </>
          ) : (
            <>
              <UserImageContainer>
                <UserImageWrapper $minSize={'12px'}>
                  <UserImage
                    src={
                      listing.user?.avatar
                        ? listing.user?.avatar
                        : images.defaultPicture
                    }
                    alt="User Profile Image"
                  />
                </UserImageWrapper>
                <ItemTitle>{listing.user?.userName}</ItemTitle>
              </UserImageContainer>
              <RatingContainer>
                <Box as={Star} width={4} height={4} />
                <BodyText3 textTransform="none">
                  {`${listing.user?.avgRate || 0} (${
                    listing.user?.totalRatings || 0
                  })`}
                </BodyText3>
              </RatingContainer>
            </>
          )}
        </SpaceBetweenContainer>
      </ItemDetailsContainer>
    </CardContainer>
  );
};

export default observer(ItemCard);
