import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { RouterProvider } from 'react-router-dom';

import theme from './theme';
import { router } from './router/router';
import Fonts from './styles/Fonts';
import { AdjustWrapper } from './components/adjust/AdjustWrapper';

const App = () => (
  <AdjustWrapper>
    <ChakraProvider theme={theme}>
      <Fonts />
      <RouterProvider router={router} />
    </ChakraProvider>
  </AdjustWrapper>
);

export default App;
