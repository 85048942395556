"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_PAYMENT_METHOD = void 0;
exports.DEFAULT_PAYMENT_METHOD = {
    id: '',
    object: '',
    billing_details: {
        address: null,
        email: '',
        name: '',
        phone: '',
    },
    card: {
        brand: '',
        checks: {
            address_line1_check: null,
            address_postal_code_check: null,
            cvc_check: '',
        },
        country: '',
        display_brand: '',
        exp_month: 0,
        exp_year: 0,
        fingerprint: '',
        funding: '',
        generated_from: null,
        last4: '',
        networks: null,
        three_d_secure_usage: { supported: false },
        wallet: null,
    },
    created: new Date(),
    customer: '',
    livemode: false,
    metadata: null,
    type: '',
};
