import React from 'react';
import { Box, Divider, Text, VStack } from '@chakra-ui/react';
import { EncoreColors } from '@Encore-FastStartup-Phase-Out/encore-shared';
import { Helmet } from 'react-helmet';

import { HomeLayout } from '../components/layouts/HomeLayout';
import { useTranslate } from '../hooks/useTranslate';
import {
  MediumHeading1,
  SmallHeading2,
  SmallHeading3,
} from '../components/typography/Headlines';
import { InfoCard } from '../components/common/InfoCard';
import { SimpleLayout } from '../components/layouts/SimpleLayout';

export const PrivacyPolicyScreen = () => {
  const t = useTranslate();
  const searchParams = new URLSearchParams(location.search);
  const app = searchParams.get('app') === 'true';
  const Layout = app ? SimpleLayout : HomeLayout;

  return (
    <Layout>
      <Helmet>
        <title>{t('privacyPolicy.title')}</title>
      </Helmet>
      <Box p={8} maxW="container.lg" mx="auto">
        <VStack spacing={8} align="start">
          <MediumHeading1>{t('privacyPolicy.title')}</MediumHeading1>
          <Text alignSelf={'end'} fontSize="sm" color={EncoreColors.Primary30}>
            {t('privacyPolicy.lastUpdated')}
          </Text>
          <Text
            dangerouslySetInnerHTML={{
              __html: t('privacyPolicy.introduction'),
            }}
          />
          <SmallHeading2>
            {t('privacyPolicy.interpretationAndDefinitions')}
          </SmallHeading2>
          <SmallHeading3>
            {t('privacyPolicy.interpretationTitle')}
          </SmallHeading3>
          <Text
            dangerouslySetInnerHTML={{
              __html: t('privacyPolicy.interpretation'),
            }}
          />
          <SmallHeading3>{t('privacyPolicy.definitionsTitle')}</SmallHeading3>
          <Text
            dangerouslySetInnerHTML={{
              __html: t('privacyPolicy.definitions'),
            }}
          />
          <Divider />

          <VStack spacing={4} align="start" mt={6}>
            <SmallHeading2>
              {t('privacyPolicy.collectingUsingData')}
            </SmallHeading2>
            <SmallHeading3>
              {t('privacyPolicy.typesOfDataCollected')}
            </SmallHeading3>
            <Text
              dangerouslySetInnerHTML={{
                __html: t('privacyPolicy.personalData'),
              }}
            />
          </VStack>

          <Divider my={6} />

          <InfoCard
            title={t('privacyPolicy.usageDataTitle')}
            description={t('privacyPolicy.usageData')}
          />
          <InfoCard
            title={t('privacyPolicy.thirdPartySocialMediaServicesTitle')}
            description={t('privacyPolicy.thirdPartySocialMediaServices')}
          />
          <InfoCard
            title={t('privacyPolicy.informationCollectedApplicationTitle')}
            description={t('privacyPolicy.informationCollectedApplication')}
          />
          <InfoCard
            title={t('privacyPolicy.useOfPersonalDataTitle')}
            description={t('privacyPolicy.useOfPersonalData')}
          />
          <InfoCard
            title={t('privacyPolicy.sharingPersonalDataTitle')}
            description={t('privacyPolicy.sharingPersonalData')}
          />
          <InfoCard
            title={t('privacyPolicy.retentionOfPersonalDataTitle')}
            description={t('privacyPolicy.retentionOfPersonalData')}
          />
          <InfoCard
            title={t('privacyPolicy.transferOfPersonalDataTitle')}
            description={t('privacyPolicy.transferOfPersonalData')}
          />
          <InfoCard
            title={t('privacyPolicy.deletePersonalDataTitle')}
            description={t('privacyPolicy.deletePersonalData')}
          />
          <InfoCard
            title={t('privacyPolicy.disclosurePersonalDataTitle')}
            description={t('privacyPolicy.disclosurePersonalData')}
          />
          <InfoCard
            title={t('privacyPolicy.securityPersonalDataTitle')}
            description={t('privacyPolicy.securityPersonalData')}
          />
          <InfoCard
            title={t('privacyPolicy.childrenPrivacyTitle')}
            description={t('privacyPolicy.childrenPrivacy')}
          />
          <InfoCard
            title={t('privacyPolicy.linksOtherWebsitesTitle')}
            description={t('privacyPolicy.linksOtherWebsites')}
          />
          <InfoCard
            title={t('privacyPolicy.privacyRightsChoicesTitle')}
            description={t('privacyPolicy.privacyRightsChoices')}
          />
          <InfoCard
            title={t('privacyPolicy.changesPrivacyPolicyTitle')}
            description={t('privacyPolicy.changesPrivacyPolicy')}
          />
          <InfoCard
            title={t('privacyPolicy.contactUsTitle')}
            description={t('privacyPolicy.contactUs')}
          />
        </VStack>
      </Box>
    </Layout>
  );
};
