import styled from '@emotion/styled';
import { EncoreColors } from '@Encore-FastStartup-Phase-Out/encore-shared';

import { Breakpoints } from '../../constants/sizes';

interface HeadingProps {
  fontWeight?: string;
  letterSpacing?: string;
  textTransform?: string;
  color?: string;
  whiteSpace?: string;
  lineHeight?: string;
  textAlign?: string;
  fontSize?: string;
}

const BaseHeading = styled.h1`
  font-family: 'Manrope bold', serif;
  font-style: normal;
  font-weight: 500;
  color: ${EncoreColors.White};
`;

const Heading1 = styled(BaseHeading.withComponent('h1'))<HeadingProps>`
  font-size: 92px;
  line-height: ${(props) => props.lineHeight || '92px'};
  font-weight: ${(props) => props.fontWeight || '800'};
  letter-spacing: ${(props) => props.letterSpacing || '-1.5px'};
  color: ${(props) => props.color || EncoreColors.White};

  @media (max-width: ${Breakpoints.Width1250}) {
    font-size: 56px;
    line-height: ${(props) => props.lineHeight || '56px'};
  }

  @media screen and (max-width: ${Breakpoints.Width800}) {
    font-size: 48px;
    line-height: ${(props) => props.lineHeight || '48px'};
  }
`;

const Heading2 = styled(BaseHeading.withComponent('h2'))<HeadingProps>`
  font-size: 56px;
  line-height: ${(props) => props.lineHeight || '68px'};
  text-align: ${(props) => props.textAlign || 'left'};
  font-weight: ${(props) => props.fontWeight || '800'};
  letter-spacing: ${(props) => props.letterSpacing || '-1.12px'};
  color: ${(props) => props.color || EncoreColors.White};

  @media screen and (max-width: ${Breakpoints.Width800}) {
    font-size: 32px;
    line-height: ${(props) => props.lineHeight || '32px'};
  }
`;

const Heading3 = styled(BaseHeading.withComponent('h3'))<HeadingProps>`
  font-size: ${(props) => props.fontSize || '36px'};
  line-height: ${(props) => props.lineHeight || '44px'};
  text-align: ${(props) => props.textAlign || 'left'};
  font-weight: ${(props) => props.fontWeight || '700'};
  letter-spacing: ${(props) => props.letterSpacing || '-0.72px'};
  color: ${(props) => props.color || EncoreColors.White};

  @media screen and (max-width: ${Breakpoints.Width800}) {
    font-size: 20px;
    line-height: ${(props) => props.lineHeight || '28px'};
  }
`;

const Heading4 = styled(BaseHeading.withComponent('h4'))<HeadingProps>`
  font-size: ${(props) => props.fontSize || '28px'};
  line-height: ${(props) => props.lineHeight || '36px'};
  letter-spacing: 0.042px;
  font-weight: ${(props) => props.fontWeight};
`;

const Heading5 = styled(BaseHeading.withComponent('h5'))<HeadingProps>`
  font-size: ${(props) => props.fontSize || '24px'};
  line-height: ${(props) => props.lineHeight || '33px'};
  font-weight: ${(props) => props.fontWeight};
  letter-spacing: ${(props) => props.letterSpacing};

  @media (max-width: ${Breakpoints.Width1250}) {
    font-size: 20px;
    line-height: ${(props) => props.lineHeight || '28px'};
  }

  @media screen and (max-width: ${Breakpoints.Width800}) {
    font-size: 16px;
    line-height: ${(props) => props.lineHeight || '28px'};
  }
`;

const Heading6 = styled(BaseHeading.withComponent('h6'))<HeadingProps>`
  font-size: 20px;
  line-height: ${(props) => props.lineHeight || '28px'};
  font-weight: ${(props) => props.fontWeight};
  letter-spacing: ${(props) => props.letterSpacing};
`;

const MediumHeading1 = Heading2.withComponent('h1');
const XSmallHeading1 = Heading3.withComponent('h1');
const SmallHeading1 = Heading4.withComponent('h1');
const SmallHeading2 = Heading5.withComponent('h2');
const SmallHeading3 = Heading6.withComponent('h3');

const StyledDiv2 = Heading2.withComponent('div');
const StyledDiv5 = Heading5.withComponent('div');

export {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  MediumHeading1,
  XSmallHeading1,
  SmallHeading1,
  SmallHeading2,
  SmallHeading3,
  StyledDiv5,
  StyledDiv2,
};
