import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { ListingType } from '@Encore-FastStartup-Phase-Out/encore-shared';
import { Helmet } from 'react-helmet';

import AuctionsPreview from '../components/itemBars/AuctionsPreview';
import { useStore } from '../hooks/useStore';
import VideoPlayer from '../components/video-player/VideoPlayer';
import ListingInfo from '../components/common/ListingInfo';
import Chat from '../components/chat/Chat';
import OpenInAppPopup from '../components/common/OpenInAppPopup';
import { getGiveawayLink, getListingLink } from '../utils/adjustLinking';
import SellerListings from '../components/itemBars/SellerListings';
import { Breakpoints } from '../constants/sizes';
import { Routes } from '../router/routes';
import MixpanelService from '../services/MixpanelService';

import { AuctionScreenWrapper } from './styledComponents';

const AuctionScreen = () => {
  const {
    listingStore: {
      currentListing,
      getListingById,
      fetchSellerListings,
      getAuctionMessagesByListing,
      currentListingMessages,
    },
    eventsStore: { unsubscribeToAllListingEvents },
    bidsStore: { listenToNewAuctionMessage, setAuctionMessages },
  } = useStore();

  const [isWidth1080] = useMediaQuery([
    `(max-width: ${Breakpoints.Width1080})`,
  ]);
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const [openInAppPopupLink, setOpenInAppPopupLink] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) return;

    (async () => {
      if (!currentListing?.id || currentListing?.id !== id) {
        try {
          await getListingById(id);
        } catch (error) {
          navigate(Routes.NotFound);
        } finally {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      } else {
        setLoading(false);
      }
    })();

    const queryParams = new URLSearchParams(location.search);
    const sharerId = queryParams.get('sharerId');
    const referrerId = queryParams.get('referrerId');

    if (sharerId) {
      const link = getListingLink(id, sharerId);
      setOpenInAppPopupLink(link);
    } else if (referrerId) {
      const link = getGiveawayLink(id, referrerId);
      setOpenInAppPopupLink(link);
    }

    return () => {
      if (currentListing) {
        unsubscribeToAllListingEvents(currentListing.id);
      }
    };
  }, [id]);

  useEffect(() => {
    if (currentListing) {
      unsubscribeToAllListingEvents(currentListing.id);
      listenToNewAuctionMessage(currentListing);
      setAuctionMessages(currentListingMessages);

      if (currentListing.listingType === ListingType.DEFAULT) {
        setLoading(true);
        fetchSellerListings(currentListing.user.id).finally(() => {
          setTimeout(() => setLoading(false), 1000);
        });
      }
    }
    return () => {
      if (currentListing) {
        unsubscribeToAllListingEvents(currentListing.id);
      }
    };
  }, [currentListing, currentListingMessages]);

  useEffect(() => {
    if (
      currentListing &&
      (currentListing.listingType === ListingType.AUCTION ||
        currentListing.listingType === ListingType.GIVEAWAY)
    ) {
      getAuctionMessagesByListing(currentListing.id);
    }
    return () => {
      if (currentListing) {
        MixpanelService.trackExitFromListing(currentListing.id);
      }
    };
  }, [currentListing]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentListing]);

  if (!currentListing) {
    return null;
  }

  return (
    <Box>
      <Helmet>
        <title>{currentListing.title}</title>
      </Helmet>
      {openInAppPopupLink && <OpenInAppPopup linkToOpen={openInAppPopupLink} />}
      <AuctionScreenWrapper>
        <>
          <ListingInfo listing={currentListing} loading={loading} />
          <VideoPlayer listing={currentListing} loading={loading} />
          {!isWidth1080 &&
            (currentListing.listingType === ListingType.AUCTION ||
            currentListing.listingType === ListingType.GIVEAWAY ? (
              <Chat listing={currentListing} loading={loading} />
            ) : (
              <SellerListings loading={loading} />
            ))}
        </>
      </AuctionScreenWrapper>
      <AuctionsPreview />
    </Box>
  );
};

export default observer(AuctionScreen);
