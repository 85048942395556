export const WebAppColors = {
  DarkBackground: '#1D1D1D',
  GreenBlur: 'rgba(0, 186, 92, 0.15)',
  GrayBackground: 'rgba(255, 255, 255, 0.10)',
  Gray1: 'rgba(44, 44, 46, 0.70)',
  Gray2: '#353538',
  Gray15: 'rgba(255, 255, 255, 0.15)',
  Gray20: 'rgba(255, 255, 255, 0.20)',
  Gray50: 'rgba(255, 255, 255, 0.50)',
  Gray60: 'rgba(255, 255, 255, 0.60)',
  Gray70: 'rgba(255, 255, 255, 0.70)',
  Green1: 'rgba(42, 227, 105, 0.10)',
  Green2: 'rgba(0, 186, 92, 0.2)',
  Red15: 'rgba(255, 86, 86, 0.15)',
  Red70: 'rgba(255, 35, 35, 0.70)',
  Error1: '#FF2323',
  Black0: 'rgba(0, 0, 0, 0)',
  Black10: 'rgba(0, 0, 0, 0.10)',
  Black30: 'rgba(0, 0, 0, 0.30)',
  Black60: 'rgba(0, 0, 0, 0.60)',
  Black80: 'rgba(0, 0, 0, 0.80)',
  Info1: '#ECB002',
  Info30: 'rgba(255, 186, 92, 0.3)',
};

export const SkeletonColors = {
  startColor: WebAppColors.GrayBackground,
  endColor: WebAppColors.GreenBlur,
};
