import { useEffect, useRef } from 'react';

type UseInfiniteScrollParams = {
  fetchMore: () => Promise<void>;
  hasMore: boolean;
};

export const useInfiniteScroll = ({
  fetchMore,
  hasMore,
}: UseInfiniteScrollParams) => {
  const sentinelRef = useRef<HTMLDivElement | null>(null);
  const isFetching = useRef(false);
  const prevScrollPosition = useRef(0);
  const prevDocumentHeight = useRef(0);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(
      async (entries) => {
        const target = entries[0];
        if (target.isIntersecting && hasMore && !isFetching.current) {
          isFetching.current = true;

          prevScrollPosition.current = window.scrollY;
          prevDocumentHeight.current = document.documentElement.scrollHeight;

          await fetchMore();

          window.scrollTo(0, prevScrollPosition.current);

          isFetching.current = false;
        }
      },
      { root: null, rootMargin: '0px', threshold: 1.0 },
    );

    if (sentinelRef.current) {
      intersectionObserver.observe(sentinelRef.current);
    }

    return () => {
      if (sentinelRef.current) {
        intersectionObserver.unobserve(sentinelRef.current);
      }
    };
  }, [hasMore, fetchMore]);

  return { sentinelRef };
};
