import { configure } from 'mobx';

import AppState from './AppState';
import LocaleStore from './LocaleStore';
import { ListingStore } from './ListingStore';
import { BidsStore } from './BidsStore';
import { EventsStore } from './EventsStore';
import { UIStore } from './UIStore';
import { UserStore } from './UserStore';

configure({ enforceActions: 'observed' });

export default class RootStore {
  public readonly appState: AppState;
  public readonly localeStore: LocaleStore;
  public readonly listingStore: ListingStore;
  public readonly bidsStore: BidsStore;
  public readonly eventsStore: EventsStore;
  public readonly uiStore: UIStore;
  public readonly userStore: UserStore;

  constructor() {
    this.appState = new AppState();
    this.localeStore = new LocaleStore(this.appState);
    this.eventsStore = new EventsStore();
    this.listingStore = new ListingStore();
    this.bidsStore = new BidsStore(this.eventsStore);
    this.uiStore = new UIStore();
    this.userStore = new UserStore();
  }
}
